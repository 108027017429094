import { template as template_5e01140a56644484bb8a70ad25d0af0a } from "@ember/template-compiler";
const FKControlMenuContainer = template_5e01140a56644484bb8a70ad25d0af0a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_6ea1a73c03d349e5adc54a74523c3b60 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6ea1a73c03d349e5adc54a74523c3b60(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_fb2cd1da29ef4081ab6e1f110e98fdfd } from "@ember/template-compiler";
const FKLabel = template_fb2cd1da29ef4081ab6e1f110e98fdfd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
